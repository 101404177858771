import { DoctorType } from 'types/entity';

import { ADMIN_PAGE, CALL_PAGE, HOME_PAGE, LOBBY_PAGE, LOGIN_PAGE } from './core/constants';

// eslint-disable-next-line object-curly-newline
const config = {
    /*
   *  Routes
   */
    installation: '/installations',
    optic: { get: { refraction: '/admin/optician/v1/refraction',
        exam: '/admin/optician/v1/getExam',
        machineConfiguration: '/admin/optician/v1/config',
        medicalPath: '/public/optician/v1' },
    post: { consultation: '/public/optician/v1/call',
        medical_path: '/public/optician/v1' } },
    terminals: { get: '/pharmacies/terminals/by-jwt',
        post: '/pharmacies/terminals/by-jwt',
        patch: '/pharmacies/terminals' },
    call: { hangup: '/calls/current/hangup',
        get: { current: '/calls/current',
            price: '/calls/price' },
        post: '/calls/create',
        put: '' },
    admin: { config: { method: 'GET',
        route: '/pharmacies/config' },
    sendDocuments: '/pharmacies/documents/send',
    login: '/pharmacies/login',
    logout: '/pharmacies/logout',
    statistics: '/pharmacies/statistics',
    doctors: '/liberal/doctors',
    carers: '/pharmacies/carers',
    rpps: '/annuaire/doctors/search' },
    nirReader: { get: '/pharmacies/nir' },
    patient: { login: '/customers/login',
        login2fa: '/customers/2fa/login',
        login2faVerify: '/customers/2fa/login/verify',
        login2faResend: '/customers/2fa/login/token/',
        login2faToggle: '/customers/2fa/toggle',
        login2faToggleVerify: '/customers/2fa/toggle/verify',
        login2faToggleResend: '/customers/2fa/toggle/token/',
        login2faCancel: '/customers/2fa/login/cancel',
        logout: '/customers/logout',
        signup: '/customers/signup',
        verify: '/customers/verify',
        verifySendAgain: '/customers/signup/next',
        cancelSignup: '/customers/signup/cancel',
        put: { carers: '/customers/carers',
            carers_relationships: '/customers/carers/relationships',
            patient: '/customers/secured',
            relative: '/relatives' },
        post: { carers: '/customers/carers',
            relative: '/relatives/create',
            verify: '/customers/secured/verify',
            secured_token: '/customers/secured/token',
            secured_update_cancel: '/customers/secured/cancel' },
        setupintent: { attach: '/cards/setupintent/attach',
            create: '/cards/setupintent/create' },
        get: { carers: '/customers/carers',
            carers_relationship: '/customers/carers/relationships',
            cards: '/cards/methods/current',
            payment_api_key: '/cards/payment_api_key',
            history: '/history/calls/customer',
            relatives: '/relatives',
            document: { prescription: '/calls/prescription',
                workleave: '/calls/workleave',
                certificate: '/calls/certificate',
                report: '/calls/report' } },
        delete: { carers: '/customers/carers' },
        sendLinkResetPassword: '/customers/recover_password' },
    locations: '/locations/public/search',
    validator: { age: { minimum: 3,
        maximum: 110 } },
    mock: { noemail: 'noemail@medadom.com',
        installation: { installation_identifier: '',
            device_model: '',
            app_version: '3.0.0',
            device_type: 'Web',
            os_version: '',
            badge: '0',
            locale_identifier: 'en',
            time_zone: 'Europe/Paris',
            is_active: true,
            is_release: true,
            bundle: '' },
        doctors: [],
        call: { call_type: DoctorType.ORTHOPTIST,
            doctor: { report: false,
                available: false },
            pharmacy: false,
            payment: { exemption: true } } },
    timeout: { call_interval: 10000,
        before_connect: 3,
        nb_socket_try: 5,
        diagnostic: 15,
        TwoFa_CodeResend_timer: 20 },
    checkInternetLobbyInterval: 5000, // check connection when waiting for doctor (in ms)
    typeOfNirReader: 'websocket', // "websocket" | "api"
    intervalNirReaderApiInS: 2,
    timeoutNirReaderInS: 60,
    intervalUpdatorInMS: 300000, // 5min
    timeoutInactivity: { doNotTriggerOn: ['/', HOME_PAGE, ADMIN_PAGE, LOGIN_PAGE, LOBBY_PAGE, CALL_PAGE],
        minutes: process.env.NODE_ENV !== 'production' ? 1 : 10 },
    disconnectTimer: { minutes: 1,
        seconds: 30 },

    authorizedFeatures: { choiceOfDoctor: ['arthur@medadom.com', 'antoine.delavelle@medadom.com'] },
    support: '01 86 65 55 15',
    newsletterSubPatientId: 12981482 };

export default config;
