import { Rule } from "antd/lib/form"
import dayjs, { Dayjs } from "dayjs"
import { ReactNode } from "react"
import config from "react-global-configuration"
import { isValidPhoneNumber } from "react-phone-number-input"

import { languages } from "./languages"
import { request } from "./request"
import { formatNirFromMask, isValidNir } from "./utils"

const hasUppercase = (value: string) => /(.*[A-Z].*)/.test(value)
const hasLowercase = (value: string) => /(.*[a-z].*)/.test(value)
const hasDigit = (value: string) => /(.*\d.*)/.test(value)
export const passwordValidator = (): {
  validator: (rule: Rule, value: string) => Promise<void>
} => ({
  async validator(rule: Rule, value: string) {
    if (!value || !value.length) return Promise.reject()
    else if (value.length < 10) {
      return await Promise.reject(languages.invalid_password)
    } else if (!hasUppercase(value) || !hasLowercase(value) || !hasDigit(value))
      return await Promise.reject(
        `${languages.incorrectInput} ${languages.password}`
      )
    else return await Promise.resolve()
  },
})

export const phoneValidator = (): {
  validator: (rule: Rule, value: string) => Promise<void>
} => {
  return {
    async validator(rule: Rule, value: string) {
      if (!value) return await Promise.resolve()
      else if (value && isValidPhoneNumber(value))
        return await Promise.resolve()
      else return await Promise.reject(languages.phoneInvalid)
    },
  }
}

export const isBirthdateBetween = (value: Dayjs, min: number, max: number) => {
  const now = dayjs()
  if (value && value.isValid()) {
    if (now.diff(value, "year") < min) return false
    if (now.diff(value, "year") > max) return false
    return true
  }
  return false
}

export const dateValidator = ({
  setFieldsValue,
}: {
  setFieldsValue: ({ birthdate }: { birthdate: Dayjs }) => void
}): {
  validator: (rule: Rule, value: Dayjs) => Promise<void>
} => ({
  async validator(rule: Rule, value: Dayjs | string) {
    let date: Dayjs | undefined = undefined
    if (typeof value === "string") date = dayjs(value, "DDMMYYYY", true)
    else date = value
    const now = dayjs()
    const minAge = config.get("validator.age.minimum")
    const maxAge = config.get("validator.age.maximum")
    if (date && date.isValid()) {
      if (now.diff(date, "year") < minAge)
        return await Promise.reject(
          languages.errorDate_shouldBeOlderThan(minAge)
        )
      if (now.diff(date, "year") > maxAge)
        return await Promise.reject(
          languages.errorDate_shouldBeYoungerThan(maxAge)
        )
      return await Promise.resolve()
    }
    return await Promise.reject(languages.dateInvalid)
  },
})

export const nirAPIValidator = async (value: string) => {
  const nir: string = formatNirFromMask(value)
  return await request("/admin/validators/nir", {
    method: "POST",
    payload: { nir },
  }).catch(() => {
    throw languages.nirInvalid
  })
}

export const nirValidator = (): {
  validator: (rule: Rule, value: string) => Promise<void>
} => ({
  async validator(_, value: string) {
    return value.length === 0 || isValidNir(value)
      ? Promise.resolve()
      : Promise.reject(languages.nirInvalid)
  },
})

export const codeValidator = (): {
  validator: (rule: Rule, value: string) => Promise<void>
} => ({
  validator(rule: Rule, value: string) {
    const code: string = value || ""
    return code.length !== 4 ? Promise.reject() : Promise.resolve()
  },
})
export const twoValidator = (): {
  validator: (rule: Rule, value: string) => Promise<void>
} => ({
  validator(rule: Rule, value: string) {
    const code: string = value || ""
    return code.length !== 6 ? Promise.reject() : Promise.resolve()
  },
})
// TO see, i'm sure there is a generic length validator for FormItem
export const lengthNirValidator = (): {
  validator: (rule: Rule, value: string) => Promise<void>
} => ({
  async validator(rule: Rule, value: string) {
    const nir: string = formatNirFromMask(value)
    return nir.length > 0 && nir.length < 15
      ? await Promise.reject(languages.nirLengthError)
      : await Promise.resolve()
  },
})

export const nameValidator = (): {
  validator: (rule: Rule, value: string) => Promise<void>
} => ({
  async validator(rule: Rule, value: string) {
    return value.length < 3
      ? await Promise.reject(languages.threeCharsMinLenght)
      : await Promise.resolve()
  },
})

export const nirLengthValidator = (): {
  validator: (rule: Rule, value: string) => Promise<void>
} => ({
  validator(rule: Rule, value: string) {
    const nir: string = formatNirFromMask(value)
    return nir.length > 0 && nir.length < 15
      ? Promise.reject(languages.nirLengthError)
      : Promise.resolve()
  },
})

export const rppsLengthValidator = (): {
  validator: (rule: Rule, value: string) => Promise<void>
} => ({
  validator(rule: Rule, value: string) {
    return value.length && value.length > 11
      ? Promise.reject(languages.rppsLengthError)
      : Promise.resolve()
  },
})

export const isPasswordValid = {
  Container: ({ children }: { children?: ReactNode }): JSX.Element => {
    return (
      <div className="password-reasons" role="alert">
        Contient au moins:
        {children}
      </div>
    )
  },
  Contenant: ({
    valid,
    text,
  }: {
    valid: boolean
    text: string
  }): JSX.Element => (
    <li className={`length password-reasons-${valid ? "valid" : "invalid"}`}>
      {text}
      <i className="fas fa-check"></i>
    </li>
  ),
  isValid: (password: string) => {
    if (
      password.length >= 10 &&
      /[A-Z]/.test(password) &&
      /[a-z]/.test(password) &&
      /[0-9]/.test(password)
    )
      return true
    return false
  },
  isMoreThanTenCharLength: (password: string): boolean => password.length >= 10,
  containOneCapsChar: (password: string): boolean => /[A-Z]/.test(password),
  containOneMinusChar: (password: string): boolean => /[a-z]/.test(password),
  containOneNumeric: (password: string): boolean => /[0-9]/.test(password),
}

export const insStringValidator = (): {
  validator: (rule: Rule, value: string) => Promise<void>
} => ({
  validator(rule: Rule, value: string) {
    if (!value || value === "") {
      return Promise.reject(languages.required)
    }
    if (value.length > 100) {
      return Promise.reject(languages.mustContainLessThanHundredCharacters)
    }
    if (value.search(/[ -]/) === 0) {
      return Promise.reject(languages.cantContainSpaceAsFirstCharacters)
    }
    if (/[']{2,}/.test(value)) {
      return Promise.reject(languages.cantContainTwoConsecutiveApostrophe)
    }
    if (/[ ]{2}/.test(value)) {
      return Promise.reject(languages.cantContainTwoConsecutiveSpace)
    }
    if (/[-]{3,}/.test(value)) {
      return Promise.reject(languages.cantContainTwoConsecutiveHyphen)
    }

    if (/[-][']/.test(value)) {
      return Promise.reject(languages.cantContainHyphenFollowedByApostrophe)
    }

    if (/[-][ ]/.test(value)) {
      return Promise.reject(languages.cantContainHyphenFollowedBySpace)
    }

    if (/[-']$/.test(value)) {
      return Promise.reject(languages.cantFinishWithHyphenOrApostrophe)
    }

    if (/^(?![a-zA-Zà-üÀ-Ü'])/.test(value)) {
      return Promise.reject(languages.shouldStartWithAlphaOrApostrophe)
    }

    if (value.search(/[a-zA-Zà-üÀ-Ü]/) === -1) {
      return Promise.reject(languages.shouldContainAtLeastOneAlphaCharacter)
    }
    if (value.search(/\//) !== -1) {
      return Promise.reject(languages.shouldNotContainSlash)
    }

    return Promise.resolve()
  }
})
